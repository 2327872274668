<template>
  <div class="add-event-management content">
    <div class="content__navbar">
      <div class="content__navbar-top">
        <div @click="$router.go(-1)" class="back">
          <img src="../../../assets/svg/arrows/back.svg" alt="">
          <p>{{ $t('back') }}</p>
        </div>
        <p class="link">{{ $t('create-an-event') }}</p>
      </div>
    </div>
    <div class="content__form">
      <user-avatar @update="form.image = $event" />
      <ui-input
          :label="$t('title')"
          v-model="form.name"
          required-field
          :error="$v.form.name.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-date-picker
          :label="$t('date')"
          v-model="form.startDate"
          required-field
          :error="$v.form.startDate.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          v-mask="'##:##'"
          :label="$t('time')"
          v-model="form.startTime"
          placeholder="__:__"
          required-field
          :error="$v.form.startTime.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('organizer')"
          v-model="form.organizer"
          required-field
          :error="$v.form.organizer.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('venue')"
          v-model="form.location"
          required-field
          :error="$v.form.location.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          :label="$t('for-whom')"
          v-model="form.forWhom"
          required-field
          :error="$v.form.forWhom.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-input
          v-mask="'#################'"
          :label="$t('participants')"
          v-model="form.participants"
          required-field
          :error="$v.form.participants.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-textarea
          :label="$t('description')"
          v-model="form.description"
          radius="10"
          height="88"
          required-field
          :error="$v.form.description.$anyError"
          :error-text="$t('fill-in-the-field')"
      />
      <ui-button @click="submit" :disabled="$v.form.$anyError || shouldDisabled">{{ $t('create') }}</ui-button>
    </div>
  </div>
</template>

<script>
import UiInput from "@/components/ui/UiInput.vue";
import UiDatePicker from "@/components/ui/UiDatePicker.vue";
import UiTextarea from "@/components/ui/UiTextarea.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {mapActions, mapMutations} from "vuex";
import UserAvatar from "@/components/common/UserAvatar.vue";
import {getDate} from "../../../utils/general";
import {minLength, required} from "vuelidate/lib/validators";

export default {
  name: "AddEventManagement",
  components: {UserAvatar, UiButton, UiTextarea, UiDatePicker, UiInput},
  data() {
    return {
      form: {
        name: '',
        date: null,
        organizer: '',
        location: '',
        forWhom: '',
        description: '',
        image: {},
        startDate: null,
        startTime: '',
        participants: null
      }
    }
  },
  methods: {
    getDate,
    ...mapActions(['reqUploadAvatar', 'addEvent', 'events']),
    ...mapMutations(['setEvents']),
    updateAvatar({ target }) {
      this.reqUploadAvatar(target.files[0]).then((res) => {
        this.form.avatar = res
        console.log(res)
      })
    },

    submit () {
      this.$v.form.$touch()
      if (!this.$v.form.$anyError) {
        const payload = {
          name: this.form.name,
          organizer: this.form.organizer,
          place: this.form.location,
          target: this.form.forWhom,
          description: this.form.description,
          image: this.form.image.filename || null,
          startDate: this.getDate(this.form.startDate),
          startTime: this.form.startTime,
          participantCnt: +this.form.participants
        }
        this.addEvent(payload).then(() => {
          this.setEvents([])
          this.$router.push('/event-management')
        })
      }
    }
  },

  computed: {
    shouldDisabled() {
      let empty = this.getFields.find(prop => !this.form[prop])
      return empty !== undefined
    },

    fieldEdit() {
      return ['name', 'organizer', 'location', 'startDate', 'startTime', 'forWhom', 'description', 'image', 'participants']
    },

    requiredRules() {
      return {
        name: {
          required,
        },
        startDate: {
          required,
        },
        startTime: {
          required,
          minLength: minLength(5)
        },
        organizer: {
          required,
        },
        location: {
          required,
        },
        forWhom: {
          required,
        },
        description: {
          required,
        },
        image: {
          required,
        },
        participants: {
          required
        }
      }
    },

    getRules() {
      return this.requiredRules
    },

    getFields() {
      return this.fieldEdit
    },
  },

  validations() {
    return {
      form: this.getRules
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  gap: 270px;
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  min-height: 100vh;

  @media (max-width: 1000px) {
    gap: 20px;
    padding: 104px 20px 50px 20px;
  }

  @media (max-width: 786px) {
    flex-direction: column;
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-top {
      display: flex;
      flex-direction: column;
      gap: 51px;

      @media (max-width: 786px) {
        gap: 20px;
      }

      .back {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }

      .link {
        color: #CE2121;
        font-size: 16px;
      }
    }

    &-bottom {
      color: #9A9A9A;
      font-size: 16px;

      @media (max-width: 786px) {
        display: none;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 434px;
    width: 100%;
    padding: 30px 34px;
    background: #FFFFFF;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);

    &-image {
      width: 100%;
      height: 193px;
      border-radius: 10px;
      margin: 0 auto;
      object-fit: cover;
    }

    &-wrap {
      width: 100%;
      height: 193px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #D9D9D9;
      border-radius: 10px;
      margin: 0 auto;

      img {
        width: 44px;
        height: 37px;
      }

      input {
        display: none;
      }
    }

    &-row {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  &__bottom {
    display: none;
    @media (max-width: 768px) {
      color: #9A9A9A;
      font-size: 16px;
      display: inline-block;
    }
  }
}
::v-deep .user-avatar {
  width: 100% !important;
  height: 193px !important;
  border-radius: 10px !important;

  img {
    width: 44px;
    height: 37px;
  }
}
</style>
